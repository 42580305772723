import React, {Component, createRef} from 'react';
import {Link} from 'gatsby';
import {graphql} from 'gatsby';
import Scrollspy from 'react-scrollspy';

import BaseLayout from '../components/Layout/BaseLayout';
import ResponsiveContainer from "../components/ResponsiveContainer";
import Dropdown from "react-bootstrap/Dropdown";

let refs = {};
let alphabet = [];

const RenderAlphabeticalMenu = ({handleClick, alphabet, list, isMobile}) => {
    return (
        (!isMobile) ?
            <Scrollspy items={alphabet} currentClassName="active" offset={-150}>
                {
                    alphabet.map((letter, i) => {
                        if (list[letter.toUpperCase()]) {
                            return <li key={i}><span onClick={() => handleClick(i)}>{letter.toUpperCase()}</span></li>
                        }
                        return <li key={i}><span className='not-available'>{letter.toUpperCase()}</span></li>
                    })
                }
            </Scrollspy>
            :
            <Dropdown className="overview-dropdown">
                <Dropdown.Toggle id="dropdown-item-button" >
                    {'Inhoudsopgave A-Z '}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {
                        alphabet.map((letter, i) => {
                            if (list[letter.toUpperCase()]) {
                                return   <Dropdown.Item
                                    key={i}
                                    as="button"
                                    onClick={() => handleClick(i)}
                                    value={letter.toUpperCase()}
                                    className={'active'}
                                >
                                    {letter.toUpperCase()}
                                </Dropdown.Item>
                            }
                            return <Dropdown.Item
                                key={i}
                                as="button"
                                onClick={() => handleClick(i)}
                                value={letter.toUpperCase()}
                                disabled={true}
                                className='not-available'
                            >
                                {letter.toUpperCase()}
                            </Dropdown.Item>
                        })
                    }
                </Dropdown.Menu>
            </Dropdown>

    )
}

class DiseasesOverview extends Component {

    constructor(props){

        super(props);

        this.state= {
            show:false
        };

        alphabet = [...Array(26).keys()].map(i => String.fromCharCode(i + 97));

        // create ref for scrolling on side-bar-menu click
        refs = alphabet.reduce((acc, value, x) => {
            acc[x] = createRef();
            return acc;
        }, {});

    }

    componentDidMount(){
        this.setState({
            show:true
        });
    }

    render() {

        const {data, location} = this.props;

        // detect support for the behavior property in ScrollOptions

        const supportsNativeSmoothScroll = (typeof document !== 'undefined') ? 'scrollBehavior' in document.documentElement.style : null;

        // create an array with all diseases
        const list = this.props.data.gravql.page.children
        .sort((a, b) => a.title.localeCompare(b.title))
        .reduce((r, e) => {
            const key = e.title[0];
            if(!r[key]) r[key] = []
            r[key].push(e);
            return r;
        }, {});


        // scroll to the section on alphabet-bar-menu click
        const handleClick = id => {
            if(refs[id].current) {

                if (typeof window !== 'undefined') {
                    const el = document.getElementById(refs[id].current.id);
                    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;

                    window.scrollTo({
                        top: yCoordinate - 150,
                        behavior: 'smooth'
                    });

                    // IE SUPPORT FOR LACK OF SMOOTH SCROLL
                    if(!supportsNativeSmoothScroll) {
                        refs[id].current.scrollIntoView({
                            block: 'center',
                        });
                    }
                }
                return;
            }
        };

        return (
            <BaseLayout show={this.state.show} data={data.gravql} location={location} crumbLabel={data.gravql.page.title}  showSubNav={true}>

                <h1>{data.gravql.page.title}</h1>

                <div className="content diseases_overview">
                <div className="sticky-menu">
                    <ResponsiveContainer>
                        <RenderAlphabeticalMenu handleClick={handleClick} alphabet={alphabet} list={list} />
                    </ResponsiveContainer>
                </div>
                {Object.entries(list)
                    .map(([key, value], i) => {
                        let letter = alphabet.indexOf(key.toLowerCase());
                        return(
                            <div ref={refs[letter]} key={i} id={key.toLowerCase()}>
                                <strong id={key.toLowerCase()}>{key}</strong>
                                <div>{value.map((item, j) => <Link key={j} to={item.route} state={{ prevPath: location.pathname }} ><h6>{item.title}</h6></Link>)}</div>
                            </div>
                        )
                })}
                 </div>

            </BaseLayout>
        )
    }
}

export default DiseasesOverview;

export const query = graphql`
 query ($slug:String!) {
    gravql {
        pages {
            title
            route
            visible
            header {
                title
                body_classes
            }
            children {
                title
                route
                header {
                    title
                    body_classes
                }
                 children {
                    title
                    route
                    header {
                        title
                        body_classes
                    }
                }
            }
        }
        page(route: $slug) {
            title      
            content
            visible
            route
            breadcrumbs
            header {
                title
                body_classes
            }
            children {
                title
                route       
                 children {
                    title
                    route
                }         
            }         
            metadata {
                name
                content
            }   
        }
    }
  }
`
